body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  cursor: pointer;
}

.input-phone-number input{
  height: 45px !important;
}
.input-phone-number input:focus{
  outline: none !important;
  border:none !important;
  box-shadow: none !important;
 
}

.horizontal-pagination .ant-pagination-prev,
.horizontal-pagination .ant-pagination-next {
  display: inline-block; /* Display previous and next buttons inline */
  vertical-align: middle; /* Align buttons vertically */
  margin-right: 10px; /* Add some margin between buttons */
}

@media only screen and (max-width: 768px) {
  .ant-pagination {
    flex-direction: row;
    justify-content: center;
  }

  .ant-pagination-item {
    margin: 0 5px;
  }
}

/* .ant-collapse {
  background: red !important; 
}

.ant-collapse-header {
  background: red !important;
  color: #ffffff !important; 
} */

/* .ant-collapse-content {
  background: red !important; 
  color: #ffffff !important; 
} */

/* .ant-collapse-item {
  border-color: red !important; 
} */
